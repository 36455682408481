import { FlexContainer } from "@/uikit/container";
import { Block } from "../base";
import { BlockContainer } from "../base/block-container";
import { Typography } from "@/uikit/Typography";
import { RemoteImage } from "@/uikit/remote-image";
import { img, impactSectionWrapper, impactWrapper, ourImpactSection, titleContainer } from "./ourImpact.css";


const OurImpact = Block("ourImpact", ({ title, subtitle, ImpactSection }) => {
    return (
        <BlockContainer layout="centeredColumn" blockPaddingTop="medium" blockPaddingBottom="default" className={ourImpactSection}>
            <FlexContainer direction="column" gap="32">
                <FlexContainer direction="column" gap="24" className={titleContainer}>
                    {title && (
                        <Typography settings={{ tag: 'h2' }}>
                            {title}
                        </Typography>
                    )}
                    {subtitle && (
                        <Typography settings={{ tag: "centeredText" }}>
                            {subtitle}
                        </Typography>
                    )}
                </FlexContainer>
                <FlexContainer direction="row" justifyContent="between" className={impactSectionWrapper}>
                    {ImpactSection && ImpactSection.map((item: any, index: any) => (
                        <div key={item._key || index} className={impactWrapper}>
                            {item.icon && (
                                <RemoteImage
                                    sizes={{
                                        mobile: "80px",
                                        tablet: "80px",
                                        desktop: "80px",
                                    }}
                                    className={img}
                                    image={item.icon}
                                />
                            )}
                            <Typography settings={{ tag: "centeredText" }}>
                                {item.description}
                            </Typography>
                        </div>
                    ))}
                </FlexContainer>
            </FlexContainer>
        </BlockContainer>
    )
})

export default OurImpact;